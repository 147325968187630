import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import dateFormat from 'dateformat';
import $ from "jquery"
import { Form, Row } from "react-bootstrap"
import { navigate } from "@reach/router"

import { getUser } from "@starberry/myaccount-website-utils"

import { ApiRequest } from "gatsby-theme-starberry-lomondgroup/src/common/utils/api_request_utils";
import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'
import ReCaptchaBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/recaptcha'
import 'gatsby-theme-starberry-lomondgroup/src/components/forms/assets/styles/_index.scss'
import { postFormData, api_form_error } from "gatsby-theme-starberry-lomondgroup/src/components/forms/api/Api"
import { FormsSubject } from "gatsby-theme-starberry-lomondgroup/src/common/form-mail-subjects"
import { phoneNumberValidation, Mail_Function } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"

import PostcodeField from "gatsby-theme-starberry-lomondgroup/src/components/forms/elements/postcode_2";
import { PageLinks } from "../../common/page-links";

function Book_A_Valuation(props) {


  const location = useLocation();
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [postcode, setPostcode] = useState("");
  const [address, setAddress] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [postCodeError, setPostCodeError] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const selectHandelChange = (name, value) => {
    setPostcode(value);
  }
  const postcodeChange = (suggestion) => {
    //setPostcode(suggestion.postcode)
    //console.log("suggestion ==>", suggestion);

    // var selected_address = suggestion.line_1 ? suggestion.line_1+", " : "";
    // selected_address += suggestion.line_2 ? suggestion.line_2+", " : "";
    // selected_address += suggestion.line_3 ? suggestion.line_3+", " : "";
    // selected_address += suggestion.postcode ? suggestion.postcode+", " : "";

    var split_address = (suggestion.trim(",")).split(",");
    var address_bind =[];
    split_address.length > 0 && split_address?.map((item) =>{
      if(item.trim()){
        address_bind.push(item)
      }
    });
    var address = address_bind.join(", ");

    //setAddress(`${selected_address}`)
    setAddress(`${address}`)
  }


  const bedList = [
    { label: '1 bed', value: 1 },
    { label: '2 bed', value: 2 },
    { label: '3 bed', value: 3 },
    { label: '4 bed', value: 4 },
    { label: '5 bed', value: 5 },
  ]
  const propertyOptions = [
    { value: 'Sell', label: 'Selling my property' },
    { value: 'Let', label: 'Letting my property' }
  ];

  const fields = ([
    {
      element: "config",
      formname: "leadpro-valuation",
      form_type: "valuation",
      error_text: "Highlighted fields are required.",
      success_text: "Please wait.. Fetching a valuation result..",
      email_temp_user: "instant_valuation_user",
      email_temp_admin: "instant_valuation_admin",
      email_server_func: "Instant-Valuation",
      event_tracking: "Instant-Valuation",
      page_url: location.href,
      email_subject_user: FormsSubject.instant_valuation.user_subject,
      email_subject_admin: FormsSubject.instant_valuation.admin_subject
    },
    {
      grpmd: "12",
      label: "First Name",
      placeholder: "",
      name: "first_name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
      labelClass: "annual-txt",
      errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
      divclass: "form-wrap",
      defaultValue: userData?.name || ``
    },
    {
      grpmd: "12",
      label: "Last Name",
      placeholder: "",
      name: "last_name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
      labelClass: "annual-txt",
      errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
      divclass: "form-wrap",
      defaultValue: userData?.surname || ``
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
      labelClass: "annual-txt",
      errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM.",
      divclass: "form-wrap",
      defaultValue: userData?.email || ``
    },
    {
      grpmd: "12",
      label: "Phone Number",
      placeholder: "",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "annual-txt",
      errorMsg: "Enter a valid phone number, Ex: 01234 567 890.",
      divclass: "form-wrap",
      defaultValue: userData?.mobile || ``
    },
    {
      grpmd: "12",
      label: "Enter a postcode",
      placeholder: "",
      name: "postcode",
      type: "text",
      element: "postcode",
      required: true,
      labelClass: "annual-txt",
      errorMsg: "Enter a valid postcode.",
      divclass: "form-wrap"
    },
    {
      grpmd: "12",
      label: "Number of Bedrooms",
      placeholder:'Select',
      name: "bedrooms",
      element: "select",
      required: true,
      values: bedList,
      divclass: "form-wrap",
      labelClass: "annual-txt",
      class: "full_wdth_box",
      optionWithLabel: true
    },
    {
      grpmd: "12",
      label: "Why do you need this valuation?",
      name: "proprty_type",
      placeholder:'Select',
      element: "select",
      required: true,
      values: propertyOptions,
      divclass: "form-wrap",
      labelClass: "annual-txt",
      class: "full_wdth_box",
      optionWithLabel: true
    },
    {
      grpmd: "12",
      label: "I agree to receive newsletters and company updates as per the Privacy Policy.",
      placeholder: "I agree to receive newsletters and company updates as per the Privacy Policy.",
      name: "signup_newsletter",
      type: "checkbox",
      element: "checkbox",
      required: true,
      value:"yes",
      labelClass: "",
      class: "checkbox_cnt",
      checked:false
    },

    {
      grpmd: "12",
      name: "SUBMIT",
      type: "submit",
      element: "button",
      value: "Submit",
      class: "btn btn-green",
      labelClass: "",
      customClass: "custom-button-wrapper mt-4",
      divclass: "form-wrap"
    },
    {
      text: 'By proceeding, you agree to the terms set out in our <a href="/' + PageLinks.privacy_policy + '/">Privacy Policy.</a>',
      element: "html",
      class: "mt-3 text-center content_r-m"
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces

    if (event.type === "select") {
      //event.value = event.value.trimStart()
      setSelectedOptions(event);
    } else {
      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token);

    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formData['name'] = formvalues['first_name'] + ' ' + formvalues['last_name']
        formData['first_name'] = formvalues['first_name']
        formData['last_name'] = formvalues['last_name']
        formData['post_code'] = postcode
        formData.append('data', JSON.stringify(formvalues));
        setShowerror(false);
        setThankyou(true);
        postFormData(formData).then(async apiRes => {

          //console.log("apiRes", apiRes); 
          window.grecaptcha.reset();

          if (apiRes?.success === true) {
            // tracking event
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'formSubmit',
              'formType': 'form-' + fields[0].event_tracking,
              'formId': 'form-' + fields[0].event_tracking,
              'formName': fields[0].formname,
              'formLabel': fields[0].formname
            });

            setTimeout(() => {
              setThankyou(false)
            }, 6000);

            if (apiRes?.leadpro_response?.status) {
              //await Mail_Function(formData);
              await Mail_Function(formvalues);
              sessionStorage.setItem('valuationResult', JSON.stringify(apiRes?.leadpro_response?.data));
              navigate('/valuation-result')
            }
            
          } else {
            setShowerror(true);
            setThankyou(false);
            setShowerrorMsg(api_form_error)
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        });
        formvalues['g-recaptcha-response'] = token;
        // myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    //setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      if (selectedOptions?.value) {
        setSelectError(false);
      } else {
        setSelectError(true);
      }

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();

      console.log("postCodeError", postCodeError)
      setSelectError(false);

      if(postCodeError){
        setShowerror(true);
        setThankyou(false);
        setShowerrorMsg("Enter a valid postcode.");

        $(".postcode-field-wrapper .form-control").addClass("is-invalid");

        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      } else{

        const formsdata = (event.target);

        const json = {}
        Object.keys(formsdata).map(key => {

          if (formsdata[key].type === "checkbox") {
            if (formsdata[key].checked === true) {
              json[formsdata[key].name] = 'yes'
            }
          } else if (formsdata[key].type === "radio") {
            if (formsdata[key].checked === true) {
              json[formsdata[key].name] = formsdata[key].value
            }
          } else {
            json[formsdata[key].name] = formsdata[key].value
          }

        })

        var date_time = dateFormat(startDate, "mmm dd, yyyy") + " " + dateFormat(startTime, "hh:MM TT");
        json['name'] = json.first_name+' '+json.last_name;
        json['email_temp_user'] = fields[0].email_temp_user;
        json['email_temp_admin'] = fields[0].email_temp_admin;
        json['email_subject_user'] = fields[0].email_subject_user;
        json['email_subject_admin'] = fields[0].email_subject_admin;
        json['postcode'] = postcode;
        json['property_address'] = json.address;
        json['sale_let'] = json.proprty_type;
        json['type'] = json.proprty_type === "Sell" ? 'vendor' : "landlord";
        json['search_type'] = json.proprty_type === "Sell" ? 'vendor' : "landlord";
        json['formname'] = fields[0].formname;
        json['g-recaptcha-response'] = token;
        json['date_time'] = date_time;
        json['extra'] = {"signup_newsletter": json.signup_newsletter};

        if(json.proprty_type === "Sell"){
          json['to_email_id'] = process.env.GATSBY_BOOK_VALUATION_MAIL_TO_SALES;
        } else if(json.proprty_type === "Let"){
          json['to_email_id'] = process.env.GATSBY_BOOK_VALUATION_MAIL_TO;
        } else{
          json['to_email_id'] = process.env.GATSBY_BOOK_VALUATION_MAIL_TO_SALES;
        }

        //console.log("formsdata_json", json);

        setFormvalues(json);
        setToken("test");

        //recaptchaRef.current.execute();

        setValidated(false);
        
        setSelectedOptions({});

        // reset form
        // const form = event.target
        // form.reset();
        setStartDate();
        setStartTime();

      }

    }
  };
  //console.log('postCodeError', postCodeError)

  //console.log("selectedOptions", selectedOptions);

  return (
    <div className="form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {/* {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>} */}


      <Form name={fields[0].formname} className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={fields[0].formname} />
        <input type="hidden" name="postcode" value={postcode} />
        <input type="hidden" name="address" value={address} />

        {/* <input type="hidden" name="to_email_id" value={process.env.GATSBY_BOOK_VALUATION_MAIL_TO} /> */}
        <input type="hidden" name="admin_email" value={"No"} />

        <input type="hidden" name="bot-field" />

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }

            if ("postcode" === field.element) {
              return (
                <PostcodeField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  selectHandelChange={selectHandelChange}
                  postcodeChange={postcodeChange}
                  setPostCodeError={setPostCodeError}
                />
              );
            } if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  optionWithLabel={field.optionWithLabel}
                  errorMsg={field.errorMsg}
                  isError={selectError}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>

                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  checked={field.checked}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={showthankyou ? "Fetching valuation result..." : field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    disable={showthankyou}
                    customClass={field.customClass}
                  />

                </>
              );
            }
          })
          }
        </Row>
      </Form>


    </div>
  );
}


const ContactFormPage = (props) => (
  <Book_A_Valuation {...props} />

)

export default ContactFormPage